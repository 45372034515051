//切位订单详情
import Q from 'q';
import web_common_buyerTourCutOrder_orderTeamDetails from '@/lib/data-service/default/web_common_buyerTourCutOrder_orderTeamDetails';


export default {
  components: {},
  data() {
    return {
      orderInfoData: {},
      linkmanArr: [],
      theVisitors: [],
      otherExpensesData: [],
      totalOther: '',
    };
  },
  computed: {},
  methods: {
    req_details(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return web_common_buyerTourCutOrder_orderTeamDetails(params);
        })
        .then(function(res) {
          let manObj = {}
          _this.orderInfoData = res.result;
          manObj.name = res.result.name;
          manObj.phone = res.result.phone;
          manObj.email = res.result.email;
          manObj.descreiption = res.result.descreiption;
          _this.linkmanArr = [manObj];

          _this.theVisitors = res.result.tourTravellerInformations;
          _this.theVisitors.forEach(function(item) {
            const crowdType = parseInt(item.crowdType);
            const touristGender = parseInt(item.touristGender);
            const certificatesType = parseInt(item.certificatesType);
            switch (crowdType) {
              case 1:
                item.crowdTypeText = '成人';
                break;
              case 4:
                item.crowdTypeText = '儿童占床 ';
                break;
              case 2:
                item.crowdTypeText = '儿童不占床';
                break;
              case 3:
                item.crowdTypeText = '老人';
                break;
            }
            switch (touristGender) {
              case 0:
                item.touristGenderText = '男';
                break;
              case 1:
                item.touristGenderText = '女';
                break;
            }
            switch (certificatesType) {
              case 1:
                item.certificatesTypeText = '身份证';
                break;
              case 2:
                item.certificatesTypeText = '护照';
                break;
              case 3:
                item.certificatesTypeText = '军官证';
                break;
              case 4:
                item.certificatesTypeText = '学生证';
                break;
              case 5:
                item.certificatesTypeText = '其他';
                break;
            }
          });

          _this.otherExpensesData = res.result.tourOtherExpenses;
          _this.otherExpensesData.forEach(function (item) {
            _this.totalOther += item.costMoneyAmount * item.costNumber;
          })
        });
    },
    initialize(query) {
      const params = { id: query.id };
      this.req_details(params);
    },
    //返回
    to_cut_ordre_list() {
      this.$router.back(-1);
    }
  },
  watch: {},
  mounted() {

  },
  activated() {
    this.initialize(this.$route.query);
  }
};
